import { COPY } from './common/copy';
import { screens } from './screens/';
import { ScreenReference, State, Answer } from './store/RootReducer';
import {
  SummaryEntry,
  SessionTrackingData,
  SessionTrackingResultEnum,
  ScreenType
} from 'src/types/data';
import { ScreenState, SessionReducerState } from './store/types';
import { ImageData, ScreenData } from 'src/types/data';

function getScreen({
  screenRef,
  state
}: {
  screenRef: ScreenReference;
  state: State;
}): ScreenData | undefined {
  const workflow = state.workflows.find(w => w.id === screenRef.workflowId);
  if (!workflow) return undefined;
  const screen = workflow.screens.find(s => s.id === screenRef.screenId);
  if (!screen) return undefined;
  return screen;
}

function getAnswer({
  screenRef,
  answers
}: {
  screenRef: ScreenReference;
  answers: Answer[];
}): Answer | undefined {
  return answers.find(
    a =>
      a.screen.screenId === screenRef.screenId &&
      a.screen.workflowId === screenRef.workflowId
  );
}

function getScreenSummary(
  screenState: ScreenState,
  sessionState: SessionReducerState
): SummaryEntry[] | null {
  switch (screenState.type) {
    case ScreenType.BUTTONS_QUESTION:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.DROPDOWN_QUESTION:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.DEVICE_SELECTOR:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.CALAMITY_SELECTOR:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.TEXT_INPUT:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.NUMBER_INPUT:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.END:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.INSTALLATION_CODE_SELECTOR:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.INSTRUCTION:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.MECHANIC:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.SPECIAL_SELECTOR:
      return screens[screenState.type].getSummary(screenState, sessionState);
    case ScreenType.SUB_SPECIAL_CODE_SELECTOR:
      return screens[screenState.type].getSummary(screenState, sessionState);
  }
}

function getScreenImages(screenState: ScreenState): ImageData[] {
  switch (screenState.type) {
    case ScreenType.BUTTONS_QUESTION:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.DROPDOWN_QUESTION:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.DEVICE_SELECTOR:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.CALAMITY_SELECTOR:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.TEXT_INPUT:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.NUMBER_INPUT:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.END:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.INSTALLATION_CODE_SELECTOR:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.INSTRUCTION:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.MECHANIC:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.SPECIAL_SELECTOR:
      return screens[screenState.type].getImages(screenState);
    case ScreenType.SUB_SPECIAL_CODE_SELECTOR:
      return screens[screenState.type].getImages(screenState);
  }
}

function buildSummary(sessionState: SessionReducerState): {
  summary: string;
  extraSummary: string;
} {
  const summary: SummaryEntry[] = sessionState.history
    .map(screenState => getScreenSummary(screenState, sessionState))
    .filter((v): v is SummaryEntry[] => v !== null)
    .flat();

  const extraSummary =
    sessionState.state.type === 'loaded' &&
    (sessionState.state.screen.type === ScreenType.END ||
      sessionState.state.screen.type === ScreenType.MECHANIC) &&
    sessionState.state.screen.screen.summary;

  return {
    summary: summary
      .concat([
        {
          title: COPY.COSTS_DISCUSSED,
          value: sessionState.extraSummaryData.costs ? COPY.YES : COPY.NO
        },
        {
          title: COPY.RESET,
          value: sessionState.extraSummaryData.reset ? COPY.YES : COPY.NO
        }
      ])
      .map(({ title, value }: SummaryEntry) => {
        return `${title.replace(/[\r\n]/g, '')}: ${value.replace(
          /[\r\n]/g,
          ''
        )}`;
      })
      .join('\n'),
    extraSummary:
      'NOS:' +
      replaceSummaryParams(
        extraSummary
          ? `${extraSummary}${
              sessionState.extraSummaryData.costs
                ? `,${COPY.COSTS_DISCUSSED}`
                : ''
            }${sessionState.extraSummaryData.reset ? `,${COPY.RESET}` : ''}`
          : '',
        sessionState.history,
        sessionState
      )
  };
}

function replaceSummaryParams(
  summary: string,
  state: ScreenState[],
  sessionState: SessionReducerState
): string {
  if (!summary) return summary;
  return summary.replace(/{{(.*?)}}/g, (match, p1) => {
    const screenState = state.find(s => s.screen.id === p1);
    if (!screenState) return 'N/A';
    const screenSummary = getScreenSummary(screenState, sessionState);
    return screenSummary ? screenSummary[0].value : 'N/A';
  });
}

function isFinalState(sessionState: SessionReducerState): boolean {
  return (
    sessionState.state.type == 'loaded' &&
    (sessionState.state.screen.type === ScreenType.END ||
      sessionState.state.screen.type === ScreenType.MECHANIC)
  );
}

function getFinalStateResult(
  sessionState: SessionReducerState
): SessionTrackingResultEnum | undefined {
  if (
    sessionState.state.type == 'loaded' &&
    sessionState.state.screen.type === ScreenType.END
  )
    return SessionTrackingResultEnum.SOLVED;

  if (
    sessionState.state.type == 'loaded' &&
    sessionState.state.screen.type === ScreenType.MECHANIC
  )
    return SessionTrackingResultEnum.MECHANIC;

  return undefined;
}

function getImages(sessionState: SessionReducerState): ImageData[] {
  const images = sessionState.history.map(getScreenImages).flat();
  if (sessionState.state.type === 'loaded') {
    const currentScreenImages = getScreenImages(sessionState.state.screen);
    images.push(...currentScreenImages);
  }
  return images.filter(image => !!image.url);
}

function getSessionTrackingData(
  sessionState: SessionReducerState
): SessionTrackingData | undefined {
  const workflowId = sessionState.rootFlowId;
  const fabricantId = sessionState.device && sessionState.device.fabricantId;
  const fabricantName =
    sessionState.device && sessionState.device.fabricantName;
  const installationId =
    sessionState.device && sessionState.device.installationId;
  const installationName =
    sessionState.device && sessionState.device.installationName;
  const result = getFinalStateResult(sessionState);

  if (!result || !workflowId) return undefined;

  const duration = new Date().getTime() - sessionState.startTime;

  return {
    workflowId,
    duration,
    fabricantId: fabricantId || undefined,
    fabricantName: fabricantName || undefined,
    installationId: installationId || undefined,
    installationName: installationName || undefined,
    result
  };
}

function isValidHttpUrl(input: string) {
  try {
    const newUrl = new URL(input);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}

type StringUrlArray = {
  type: 'url' | 'string';
  value: string;
}[];

function breakUrlString(input: string): StringUrlArray {
  const chunks = input.split(' ');
  let lastStringIndex = 0;
  const outputArray: StringUrlArray = [];
  chunks.forEach((chunk, index) => {
    if (isValidHttpUrl(chunk)) {
      outputArray.push({
        type: 'url',
        value: chunk
      });
      lastStringIndex = outputArray.length;
    } else {
      const trailingSpace = index < chunks.length - 1 ? ' ' : '';
      if (lastStringIndex === outputArray.length) {
        const precedingSpace = index > 0 ? ' ' : '';
        outputArray.push({
          type: 'string',
          value: precedingSpace + chunk + trailingSpace
        });
      } else {
        outputArray[lastStringIndex].value += chunk + trailingSpace;
      }
    }
  });
  return outputArray;
}

export {
  getScreen,
  getAnswer,
  getScreenSummary,
  isFinalState,
  getImages,
  buildSummary,
  getSessionTrackingData,
  isValidHttpUrl,
  breakUrlString
};
